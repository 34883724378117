import * as React from 'react';
import MDialog from '../../../components/controls/MDialog';
import { Box, Button, Grid, InputLabel, TextField, CircularProgress, Checkbox, Skeleton } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import { DataGrid } from '@mui/x-data-grid';
import { useNotify } from 'react-admin';
import MRadio from '../../../components/controls/MRadio';
import MSelect from '../../../components/controls/MSelect';
import { ExchangeMarket } from '../../../utils/enum';
import MNumber from '../../../components/controls/MNumber';
import { REMOTE_API, getDataUpstream, objectToFormData } from '../utils';

const Item = ({ children, label, marginTop }) => {
    return (
        <Grid item container spacing={5}>
            <Grid item xs={2} sx={{ textAlign: 'right' }}>
                <InputLabel id={label} sx={{ marginTop: marginTop != null ? marginTop : 3 }}>{label}</InputLabel>
            </Grid>
            <Grid item xs={10}>
                {children}
            </Grid>
        </Grid>
    );
};

const SourceEditPopup = ({ data, marketType, sourceVariable, dataProvider, handleClose }) => {
    const { isEdit, source } = data;
    const notify = useNotify();
    const [rows, setRows] = React.useState([]);
    const [configDialog, setConfigDialog] = React.useState({
        isGetVariable: false
    });
    const [state, setState] = React.useState({
        title: 'Edit source',
        source: source,
        isChange: false,
        isFirstLoadUpstream: true,
        isLoadedUpstream: false,
        isLoading: false // for update process
    });

    React.useEffect(() => {
        initUpstream();
    }, [marketType]);

    React.useEffect(() => {
        setState((prev) => ({ ...prev, source: { ...prev.source, ...getDataUpstream(source.Url), ListUpstream: [] } }));
    }, [source]);

    React.useEffect(() => {
        if (rows.length && sourceVariable.length && source && !state.isLoadedUpstream) {
            loadUpstream();
        }
    }, [rows, state.isLoadedUpstream, sourceVariable.length]);

    React.useEffect(() => {
        if (!configDialog.isGetVariable && sourceVariable.length && rows.length) {
            var rowsChecked = rows.filter(x => x.checked);
            var listVariable = rowsChecked.map(m => m.variable.pricer).concat(rowsChecked.filter(x => x.variable.rate.var_decimal_value != null).map(m => m.variable.rate));
            const loadListingVariable = async () => {
                try {
                    const response = await dataProvider.postData('ListingVariable', `GetList`, listVariable);
                    if (response && response.data.length) {
                        updateRowDataByAutoPricer(true, response.data);
                    }
                } catch (error) {
                    setState((prev) => ({ ...prev, isLoading: false }));
                    notify(`Load rule error: ${error}`, { type: 'error' });
                }
            };
            loadListingVariable();
            setConfigDialog((prev) => ({ ...prev, isGetVariable: true }));
        }
    }, [sourceVariable.length, rows.length]);

    React.useEffect(() => {
        setFormattedUrl();
    }, [state.isChange]);

    function initUpstream() {
        var _newRows = [];
        (marketType || []).map((_us, index) => {
            var item = {
                id: index,
                checked: false,
                upstream: _us.name,
                type: '',
                top: '',
                formatted: '',
                rate: '',
                autopricer: false,
                options: { type: false, top: false },
                // Update tts-upstreams to allow apply VIVID_EL2.accesso.pricer.active for specific markets
                variable: { pricer: {}, rate: {} }
            };
            _newRows.push(item);
        });

        setRows(_newRows);
    }

    function loadUpstream() {
        var newSource = state.source.ListUpstream;
        if (state.source.Upstream) {
            var updatedRows = rows;
            var upstreams = source.Upstream.split(',');
            upstreams.forEach((_us) => {
                var _upstr = _us.split('.');
                var index = updatedRows.findIndex(x => x.upstream == _upstr[0]);
                if (index >= 0) {
                    var item = updatedRows[index];
                    item.checked = true;
                    if (!newSource.find(x => x == _upstr[0])) {
                        newSource.push(_upstr[0]);
                    }
                    if (_upstr[1]) {
                        if (_upstr[1].includes('top')) item.top = getTop(_upstr[1]);
                        else item.type = _upstr[1];
                    }
                    if (_upstr[2]) item.top = getTop(_upstr[2]);
                    item.formatted = getFormatted(item.checked, item.upstream, item.type, item.top);
                    item.options = { type: true, top: true };
                    // Load variable for pricer & rate
                    item.autopricer = state.source.AutoPricer;
                    var realUpstream = sourceVariable.find(x => x.id == item.upstream.toLowerCase());
                    if (realUpstream) {
                        var pricer_varname = `${state.source.Name}.${realUpstream.variableName}.pricer.active`;
                        var pricer_value = state.source.AutoPricer ? 1 : 0;
                        item.variable.pricer = {
                            id: 0,
                            sm_market: state.source.Market,
                            market_name: state.source.Name,
                            var_name: pricer_varname,
                            var_decimal_value: pricer_value
                        }
                        item.variable.rate = {
                            id: 0,
                            sm_market: state.source.Market,
                            market_name: state.source.Name,
                            var_name: `${state.source.Name}.${realUpstream.variableName}.pricer.rate`,
                            var_decimal_value: 0
                        }
                    }
                }
            });
            setRows(updatedRows);
        }
        var anothers = state.source.Anothers || '';
        if (anothers[0] == '&') {
            anothers = anothers.slice(1, anothers.length);
        }
        anothers = anothers ? `?${anothers}` : '';
        setState((prev) => ({ ...prev, source: { ...prev.source, Url: `${state.source.BaseUrl}${anothers}` }, isChange: !prev.isChange, isLoadedUpstream: true, isFirstLoadUpstream: false }));

        function getTop(value) {
            return value.match(/(?:top)(\w+)/)[1];
        }
    }

    function getFormatted(checked, upstream, type, top) {
        var formatted = '';
        if (checked) {
            formatted = `${upstream}${type ? '.' + type : ''}${top ? '.top' + top : ''}`;
        }
        return formatted;
    }

    function setFormattedUrl() {
        if (state.source.ListUpstream) {
            var market = state.source.Market;
            var autopricer = state.source.AutoPricer ? 1 : 0;
            var _upstreams = '';
            state.source.ListUpstream.forEach((upstr) => {
                let formatted = rows.find(x => x.upstream == upstr).formatted || '';
                _upstreams = _upstreams.concat(`${formatted},`);
            });
            _upstreams = _upstreams.slice(0, _upstreams.length - 1); // Remove last character
            _upstreams = _upstreams ? `upstream=${_upstreams}&` : '';
            market = market ? `market=${market}&` : '';
            var dataStream = `${_upstreams}${market}autopricer=${autopricer}`;
            dataStream = dataStream ? `?${dataStream}` : '';
            var another = state.source.Anothers;
            if (dataStream) {
                another = another ? `&${another}` : '';
            } else {
                another = another ? `?${another}` : '';
            }
            var formattedUrl = `${state.source.BaseUrl}${dataStream}`;
            // Remove & at end if exist
            if (formattedUrl[formattedUrl.length - 1] == '&') {
                formattedUrl = formattedUrl.slice(0, formattedUrl.length - 1);
            }
            formattedUrl = `${formattedUrl}${another}`;
            setState((prev) => ({ ...prev, source: { ...prev.source, Formatted: formattedUrl } }));
        }
    }

    const handleChangeUrl = (e) => {
        const { value } = e.target;
        setState((prev) => ({ ...prev, source: { ...prev.source, ...getDataUpstream(value, prev.source.Formatted), Url: value }, isChange: !prev.isChange }));
    };

    const handleChecked = (event, row) => {
        const { checked } = event.target;
        var newUpstreams = [];
        updateRowData((r) => {
            if (r.id === row.id) {
                r.checked = checked;
                r.options = { type: checked, top: checked };
                if (checked) {
                    if (!state.source.ListUpstream.find(x => x == row.upstream)) {
                        newUpstreams.push(r.upstream);
                    }
                    r.formatted = getFormatted(checked, r.upstream, r.type, r.top);
                } else {
                    let index = state.source.ListUpstream.findIndex(x => x == row.upstream);
                    if (index >= 0) {
                        newUpstreams.splice(index, 1);
                    }
                    r.formatted = getFormatted(checked, r.upstream);
                }
            } else if (r.checked) {
                newUpstreams.push(r.upstream);
            }
        });
        setState((prev) => ({ ...prev, source: { ...prev.source, ListUpstream: newUpstreams } }));
    };

    const handleCheckAutoPricer = (event, row) => {
        const { checked } = event.target;
        updateRowData((r) => {
            if (r.id === row.id && r.checked) {
                r.autopricer = checked;
                var realUpstream = sourceVariable.find(x => x.id == row.upstream.toLowerCase());
                if (realUpstream) {
                    var varname = `${state.source.Name}.${realUpstream.variableName}.pricer.active`;
                    var value = checked ? 1 : 0;
                    r.variable.pricer = { ...r.variable.pricer, var_name: varname, var_decimal_value: value };
                }
            }
        }, false);
    };

    const handleRate = (value, row) => {
        updateRowData((r) => {
            if (r.id === row.id && r.autopricer) {
                var realUpstream = sourceVariable.find(x => x.id == row.upstream.toLowerCase());
                if (realUpstream) {
                    var varname = `${state.source.Name}.${realUpstream.variableName}.pricer.rate`;
                    r.variable.rate = { ...r.variable.rate, var_name: varname, var_decimal_value: value };
                }
            }
        }, false);
    };

    const handleTop = (value, row) => {
        updateRowData((r) => {
            if (r.id === row.id) {
                r.top = value;
                r.formatted = getFormatted(r.checked, r.upstream, r.type, value);
            }
        });
    };

    const handleType = (event, row) => {
        let value = event?.target?.value ?? event;
        updateRowData((r) => {
            if (r.id === row.id) {
                r.type = value;
                r.formatted = getFormatted(r.checked, r.upstream, value, r.top);
            }
        });
    };

    const handleAutoPricer = (event) => {
        const { checked } = event.target;
        setState((prev) => ({ ...prev, source: { ...prev.source, AutoPricer: checked }, isChange: !prev.isChange }));
        updateRowDataByAutoPricer(checked);
    };

    function updateRowDataByAutoPricer(checked, data = []) {
        updateRowData((r) => {
            if (r.id === r.id && r.checked) {
                if (checked) r.autopricer = checked;
                if (data.length) { // load autopricer & rate from DB
                    let existPricer = data.find(x => x.var_name.includes(r.variable.pricer.var_name) && r.variable.pricer.var_name.includes('.pricer.active'));
                    if (existPricer) {
                        r.autopricer = existPricer.var_decimal_value == 1 ? true : false;
                        r.variable.pricer = existPricer;
                    } else {
                        r.autopricer = false;
                    }
                    let existRate = data.find(x => x.var_name.includes(r.variable.rate.var_name) && r.variable.rate.var_name.includes('.pricer.rate'));
                    if (existRate) {
                        r.rate = existRate.var_decimal_value;
                        r.variable.rate = existRate;
                    }
                } else {
                    var realUpstream = sourceVariable.find(x => x.id == r.upstream);
                    if (realUpstream) {
                        var varname = `${state.source.Name}.${realUpstream.variableName}.pricer.active`;
                        r.variable.pricer.var_name = varname;
                        r.variable.pricer.var_decimal_value = checked ? 1 : 0;
                        r.rate = r.variable.rate.var_decimal_value;
                    }
                }
            }
        }, false);
    };

    const handleMarket = (event) => {
        let value = event?.target?.value ?? event;
        setState((prev) => ({ ...prev, source: { ...prev.source, Market: value }, isChange: !prev.isChange }));
    };

    function updateRowData(callback, isChange = true) {
        const updatedRows = rows.map((r) => {
            callback(r);
            return r;
        });
        setRows(updatedRows);
        if (isChange) setState((prev) => ({ ...prev, isChange: !prev.isChange }));
    }

    const handleUpdate = () => {
        var newSource = {
            Url: state.source.Formatted,
            Type: state.source.Type,
            Name: state.source.Name,
            Description: state.source.Description,
            is_disabled: state.source.is_disabled,
            Id: state.source.Id
        };
        setState((prev) => ({ ...prev, isLoading: true }));
        dataProvider.postData(REMOTE_API, 'sources', objectToFormData(newSource))
            .then(function () {
                notify(`Source was updated`, { type: 'success' });
                if (state.source.AutoPricer) {
                    var rowsChecked = rows.filter(x => x.checked);
                    var listVariable = rowsChecked.map(m => m.variable.pricer).concat(rowsChecked.map(m => m.variable.rate));
                    dataProvider.postData('ListingVariable', `UpdateListVariable`, listVariable)
                        .then(function () {
                            handleClose();
                            notify(`ListingVariable was updated`, { type: 'success' });
                            setState((prev) => ({ ...prev, isLoading: false }));
                        }).catch((error) => {
                            notify(`ListingVariable Error: ${error}`, { type: 'error' });
                            handleClose();
                        });
                } else {
                    handleClose();
                    setState((prev) => ({ ...prev, isLoading: false }));
                }
            }).catch((error) => {
                notify(`Update Error: ${error}`, { type: 'error' });
            });
    };

    return (
        <MDialog name='m_isOpen'
            open={isEdit}
            title={state.title}
            handleClose={handleClose}
            maxWidth="lg"
            action={<Button startIcon={state.isLoading ? <CircularProgress size={20} /> : <SendIcon />} onClick={handleUpdate}>Update</Button>}
        >
            <Grid container
                justifyContent="flex-start"
                alignItems="flex-start"
            >
                <Item label={'Name'} marginTop={0}>
                    <InputLabel id="Name" >{state.source.Name}</InputLabel>
                </Item>
                <Item label={'Type'}>
                    <MRadio name={"Type"} value={state.source.Type}
                        choices={[{ id: 0, name: 'Vivid' }, { id: 1, name: 'TN Impoter' }, { id: 2, name: 'TN Uploader' }, { id: 13, name: 'GC Uploader' }]}
                        onChange={(value) => setState((prev) => ({ ...prev, source: { ...prev.source, Type: value } }))} />
                </Item>
                <Item label={'Base Url'}>
                    <TextField id='Url' name='Url' placeholder='Upstream url eg.. http://www.tickettoolshed.com/report...' value={state.source.Url} sx={{ width: '100%' }} onChange={handleChangeUrl} />
                </Item>

                <Item label={'Formatted'} marginTop={0}>
                    <InputLabel style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word' }} id="Formatted">{state.source.Formatted}</InputLabel>
                </Item>
                <Item label={'Market'}>
                    <Grid item container spacing={1}>
                        <Grid item xs={6}>
                            <MSelect name="Market" choices={ExchangeMarket} value={state.source.Market} onChange={handleMarket} />
                        </Grid>
                        <Grid item container xs={6}>
                            <Grid item xs={6} sx={{ textAlign: 'center', paddingTop: 3 }}>
                                <InputLabel>{'Auto Pricer'}</InputLabel>
                            </Grid>
                            <Grid item xs={6} sx={{ paddingTop: 2 }}>
                                <Checkbox name='AutoPricer' checked={state.source.AutoPricer} onChange={handleAutoPricer} />
                            </Grid>
                        </Grid>
                    </Grid>
                </Item>
                <Item label={'Description'}>
                    <TextField id='Description' name='Description' sx={{ width: '100%' }} value={state.source.Description}
                        onChange={(event) => setState((prev) => ({ ...prev, source: { ...prev.source, Description: event.target.value } }))} />
                </Item>
                <Item label={'Disabled'} >
                    <Checkbox name='is_disabled' sx={{ marginTop: 1.5 }} checked={state.source.is_disabled}
                        onChange={(event) => setState((prev) => ({ ...prev, source: { ...prev.source, is_disabled: event.target.checked } }))} />
                </Item>
            </Grid>
            {state.isFirstLoadUpstream ? <Skeleton /> :
                <Box sx={{ maxHeight: 400, width: '100%', overflowY: 'auto', paddingLeft: 5 }}>
                    <DataGrid
                        autoHeight
                        hideFooter
                        rows={rows}
                        columns={[
                            {
                                field: 'checked', headerName: 'Checked', width: 100, renderCell: (params) => {
                                    return (
                                        <Checkbox checked={params.row.checked} onChange={(event) => handleChecked(event, params.row)} />
                                    );
                                }
                            },
                            {
                                field: 'upstream', headerName: 'Upstream', width: 150
                            },
                            {
                                field: 'type', headerName: 'Type', width: 150, renderCell: (params) => {
                                    return (
                                        <MSelect variant='outlined'
                                            value={params.row.type}
                                            disabled={!params.row.options.type}
                                            choices={[{ id: 'lm', name: 'lm' }, { id: 'range', name: 'range' }]}
                                            onChange={(event) => handleType(event, params.row)} />
                                    );
                                }
                            },
                            {
                                field: 'top', headerName: 'Top', width: 150, renderCell: (params) => {
                                    return (
                                        <MNumber value={params.row.top} disabled={!params.row.options.top} onChange={(value) => handleTop(value, params.row)} variant='outlined' />
                                    );
                                }
                            },
                            {
                                field: 'formatted', headerName: 'Formatted', width: 200
                            },
                            {
                                field: 'autopricer', headerName: 'Auto Pricer', width: 100, renderCell: (params) => {
                                    return (
                                        <Checkbox checked={params.row.autopricer} disabled={!params.row.checked || !state.source.AutoPricer} onChange={(event) => handleCheckAutoPricer(event, params.row)} />
                                    );
                                }
                            },
                            {
                                field: 'rate', headerName: 'Rate', width: 150, renderCell: (params) => {
                                    return (
                                        <MNumber value={params.row.rate} disabled={!params.row.checked || !params.row.autopricer || !state.source.AutoPricer} decimal={5} onChange={(value) => handleRate(value, params.row)} variant='outlined' />
                                    );
                                }
                            }
                        ]}
                    />
                </Box>}
        </MDialog>
    );
};

export default SourceEditPopup;