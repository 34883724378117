import * as React from 'react';
import MDialog from "../../../../../components/controls/MDialog";
import { Box, Button, LinearProgress } from '@mui/material';
import { DataGrid, useGridApiRef } from '@mui/x-data-grid';
import { useNotify } from 'react-admin';
import { formatDateTime } from '../../../../../components/ex_dayjs';

const SuggestAccountsPopup = ({ vendor, dataProvider, data, onClose, onChange }) => {
    const { order } = data;
    const { s_isOpen } = data.dlgState;
    const [isLoading, setIsLoading] = React.useState(false);
    const [rows, setRows] = React.useState([]);
    const [rowSelected, setRowSelected] = React.useState();
    const [isShowEventDate, setShowEventDate] = React.useState(false);
    const apiRef = useGridApiRef();
    const notify = useNotify();

    const initialState = {
        hideColumns: { eventDate: false }
    };

    React.useEffect(() => {
        setRows([]);
    }, []);

    React.useEffect(() => {
        if (s_isOpen && order) {
            setIsLoading(true);
            dataProvider.fetchData(vendor.controller, `SuggestAccounts?orderId=${order.id}`)
                .then((response) => {
                    var result = response.data;
                    if (result.success) {
                        if (result.data.some(e => e.eventDate)) {
                            apiRef.current.setColumnVisibilityModel({});
                            setShowEventDate(true);
                        }
                        setRows(result.data);
                    }
                    setIsLoading(false);
                })
                .catch(err => {
                    notify(`Get sussgest accounts has error ${err}`, { type: 'error', autoHideDuration: 2000 });
                    setIsLoading(false);
                });
        }
    }, [s_isOpen, order]);

    const columnDefs = [
        { field: 'primaryAccount', headerName: 'Primary Account', flex: 1 },
        { field: 'eventDate', headerName: 'Event Date', valueFormatter: params => { return formatDateTime(params.value); }, flex: 0.8 },
        { field: 'totalTickets', headerName: 'Total Tickets', flex: 0.5, type: 'number' },
        { field: 'totalOrders', headerName: 'Total Orders', flex: 0.5, type: 'number' },
        { field: 'limitTickets', headerName: 'Limit Tickets', flex: 0.5, type: 'number' },
        { field: 'limitOrders', headerName: 'Limit Orders', flex: 0.5, type: 'number' },
        // { field: 'state', headerName: 'State', flex: 0.3 },
        // { field: 'city', headerName: 'City', flex: 0.5 },
    ];

    const closeDialog = () => onClose(null, 's_isOpen');

    const onCellClick = (params) => {
        setRowSelected(params.row.primaryAccount);
    }

    const onRowDoubleClick = (params) => {
        onChange(params.row.primaryAccount);
        closeDialog();
    }

    const onSelect = () => {
        onChange(rowSelected);
        closeDialog();
    }

    return (
        <MDialog
            className={'order-dialog'}
            name={'s_isOpen'}
            open={s_isOpen}
            title={"Accounts Suggestion"}
            handleClose={onClose}
            maxWidth="md"
            action={<Button variant="contained" onClick={onSelect}>OK</Button>}
        >
            {isLoading && <LinearProgress color='secondary' />}
            <Box sx={{ height: 300, width: '100%' }}>
                <DataGrid
                    apiRef={apiRef}
                    rows={rows}
                    columns={columnDefs}
                    autoPageSize={true}
                    density="compact"
                    getRowId={(x) => isShowEventDate ? x.primaryAccount + '_' + x.eventDate : x.primaryAccount}
                    initialState={{
                        pagination: {
                            paginationModel: {
                                pageSize: 25,
                            },
                        },
                        columns: {
                            columnVisibilityModel: initialState.hideColumns
                        },
                    }}
                    onCellClick={onCellClick}
                    onRowDoubleClick={onRowDoubleClick}
                />
            </Box>
        </MDialog>
    )
}

export default React.memo(SuggestAccountsPopup);