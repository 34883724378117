import * as React from 'react';
import { Menu, List, ListItem, ListItemButton, ListDivider } from '@mui/joy';
import MenuItem, { menuItemClasses } from '@mui/joy/MenuItem';
import Typography, { typographyClasses } from '@mui/joy/Typography';
import Dropdown from '@mui/joy/Dropdown';
import MenuButton from '@mui/joy/MenuButton';
import { Link } from 'react-router-dom';

const MenuBarButton = React.forwardRef(
    ({ children, menu, open, onOpen, onKeyDown, ...props }, ref) => {
        return (
            <Dropdown open={open} onOpenChange={onOpen}>
                <MenuButton
                    {...props}
                    slots={{ root: ListItemButton }}
                    ref={ref}
                    role="menuitem"
                    variant={open ? 'soft' : 'plain'}
                >
                    {children}
                </MenuButton>
                {React.cloneElement(menu, {
                    slotProps: {
                        listbox: {
                            id: `toolbar-example-menu-${children}`,
                            'aria-label': children,
                        },
                    },
                    placement: 'bottom-start',
                    disablePortal: false,
                    variant: 'soft',
                    sx: (theme) => ({
                        width: 288,
                        boxShadow: '0 2px 8px 0px rgba(0 0 0 / 0.38)',
                        '--List-padding': 'var(--ListDivider-gap)',
                        '--ListItem-minHeight': '32px',
                        [`&& .${menuItemClasses.root}`]: {
                            transition: 'none',
                            '&:hover': {
                                ...theme.variants.solid.primary,
                                [`& .${typographyClasses.root}`]: {
                                    color: 'inherit',
                                },
                            },
                        },
                    }),
                })}
            </Dropdown>
        );
    },
);

export default function MenuToolbarExample() {
    const menus = React.useRef([]);
    const [menuIndex, setMenuIndex] = React.useState(null);

    const renderShortcut = (text) => (
        <Typography level="body-sm" textColor="text.tertiary" ml="auto">
            {text}
        </Typography>
    );

    const openNextMenu = () => {
        if (typeof menuIndex === 'number') {
            if (menuIndex === menus.current.length - 1) {
                setMenuIndex(0);
            } else {
                setMenuIndex(menuIndex + 1);
            }
        }
    };

    const openPreviousMenu = () => {
        if (typeof menuIndex === 'number') {
            if (menuIndex === 0) {
                setMenuIndex(menus.current.length - 1);
            } else {
                setMenuIndex(menuIndex - 1);
            }
        }
    };

    const handleKeyDown = (event) => {
        if (event.key === 'ArrowRight') {
            openNextMenu();
        }
        if (event.key === 'ArrowLeft') {
            openPreviousMenu();
        }
    };

    const createHandleButtonKeyDown = (index) => (event) => {
        if (event.key === 'ArrowRight') {
            if (index === menus.current.length - 1) {
                menus.current[0]?.focus();
            } else {
                menus.current[index + 1]?.focus();
            }
        }
        if (event.key === 'ArrowLeft') {
            if (index === 0) {
                menus.current[menus.current.length]?.focus();
            } else {
                menus.current[index - 1]?.focus();
            }
        }
    };

    const itemProps = {
        onClick: () => setMenuIndex(null),
        onKeyDown: handleKeyDown,
    };

    return (
        <List
            orientation="horizontal"
            aria-label="Example application menu bar"
            role="menubar"
            data-joy-color-scheme="dark"
            sx={{
                bgcolor: 'background.paper',
                borderRadius: '4px',
                maxWidth: 'fit-content',
            }}
        >
            <ListItem>
                <MenuBarButton
                    open={menuIndex === 0}
                    onOpen={() => {
                        setMenuIndex((prevMenuIndex) => (prevMenuIndex === null ? 0 : null));
                    }}
                    onKeyDown={createHandleButtonKeyDown(0)}
                    onMouseEnter={() => {
                        if (typeof menuIndex === 'number') {
                            setMenuIndex(0);
                        }
                    }}
                    ref={(instance) => {
                        menus.current[0] = instance;
                    }}
                    menu={
                        <Menu
                            onClose={() => {
                                menus.current[0]?.focus();
                            }}
                        >
                            <ListItem nested>
                                <List aria-label="Time travel">
                                    <MenuItem {...itemProps} component={Link} to="/SmEventTickets/">SM Event Tickets</MenuItem>
                                    <MenuItem {...itemProps} component={Link} to='/SingleEventAnalysis/'>Order Detail Analysis</MenuItem>
                                    <MenuItem {...itemProps} component={Link} to='/ExchangeTicketCount/'>TN Sales Analysis</MenuItem>
                                </List>
                            </ListItem>                            
                        </Menu>
                    }
                >
                    Analysis
                </MenuBarButton>
            </ListItem>
            <ListItem>
                <MenuBarButton
                    open={menuIndex === 1}
                    onOpen={() => {
                        setMenuIndex((prevMenuIndex) => (prevMenuIndex === null ? 1 : null));
                    }}
                    onKeyDown={createHandleButtonKeyDown(1)}
                    onMouseEnter={() => {
                        if (typeof menuIndex === 'number') {
                            setMenuIndex(1);
                        }
                    }}
                    ref={(instance) => {
                        menus.current[1] = instance;
                    }}
                    menu={
                        <Menu
                            onClose={() => {
                                menus.current[1]?.focus();
                            }}
                        >
                            <ListItem nested>
                                <List aria-label="Order">
                                    {/* <MenuItem {...itemProps} component={Link} to='/Vivid/'>Vivid Order</MenuItem> */}
                                    {/*
                                    <MenuItem {...itemProps} component={Link} to='/TicketNetwork/'>TicketNetwork Order</MenuItem>
                                    <MenuItem {...itemProps} component={Link} to='/GoTickets/'>GoTickets Order</MenuItem>
                                     */}
                                    <ListDivider />
                                    <MenuItem {...itemProps} component={Link} to='/ShippingReport/'>Shipping Report</MenuItem>
                                    <MenuItem {...itemProps} component={Link} to='/OrderMistakeReport/'>Order Mistaken Report</MenuItem>
                                    <MenuItem {...itemProps} component={Link} to='/OrderAutoPriceReport/'>Order Auto Price Report</MenuItem>
                                    <MenuItem {...itemProps} component={Link} to='/OneCallNow/'>One Call Now Report</MenuItem>
                                    <ListDivider />
                                    <MenuItem {...itemProps} component={Link} to="/OrderAdjustRule/">Order Adjust Rule</MenuItem>
                                </List>
                            </ListItem>
                        </Menu>
                    }
                >
                    Orders
                </MenuBarButton>
            </ListItem>
            <ListItem>
                <MenuBarButton
                    open={menuIndex === 2}
                    onOpen={() => {
                        setMenuIndex((prevMenuIndex) => (prevMenuIndex === null ? 2 : null));
                    }}
                    onKeyDown={createHandleButtonKeyDown(2)}
                    onMouseEnter={() => {
                        if (typeof menuIndex === 'number') {
                            setMenuIndex(2);
                        }
                    }}
                    ref={(instance) => {
                        menus.current[2] = instance;
                    }}
                    menu={
                        <Menu
                            onClose={() => {
                                menus.current[2]?.focus();
                            }}
                        >
                            <ListItem nested>
                                <List aria-label="Time travel">
                                    <MenuItem {...itemProps} component={Link} to='/Listings/'>Listings</MenuItem>
                                    <MenuItem {...itemProps} component={Link} to='/EventDelivery/'>Event Delivery </MenuItem>
                                    <MenuItem {...itemProps} component={Link} to='/NgaVenueConfig/'>Row Range for SPEC </MenuItem>
                                    <ListDivider />
                                    <MenuItem {...itemProps} component={Link} to="/ListingsMapping/">Event Mapping</MenuItem>
                                    <MenuItem {...itemProps} component={Link} to="/EventMatcher/">Event Matcher</MenuItem>
                                    <ListDivider />
                                    <MenuItem {...itemProps} component={Link} to='/ListingVariable/'>List variable </MenuItem>
                                    <MenuItem {...itemProps} component={Link} to="/SourceVariable/">Source Variables</MenuItem>
                                </List>
                            </ListItem>
                        </Menu>
                    }
                >
                    Listings
                </MenuBarButton>
            </ListItem>
            <ListItem>
                <MenuBarButton
                    open={menuIndex === 3}
                    onOpen={() => {
                        setMenuIndex((prevMenuIndex) => (prevMenuIndex === null ? 3 : null));
                    }}
                    onKeyDown={createHandleButtonKeyDown(3)}
                    onMouseEnter={() => {
                        if (typeof menuIndex === 'number') {
                            setMenuIndex(3);
                        }
                    }}
                    ref={(instance) => {
                        menus.current[3] = instance;
                    }}
                    menu={
                        <Menu
                            onClose={() => {
                                menus.current[3]?.focus();
                            }}
                        >
                            <ListItem nested>
                                <List aria-label="Time travel">
                                    <MenuItem {...itemProps} component={Link} to="/DivvyTransaction/">Divvy Transactions</MenuItem>
                                </List>
                            </ListItem>
                            <ListDivider />
                            <ListItem nested>
                                <List aria-label="Time travel">
                                    <MenuItem {...itemProps} component={Link} to="/OrderSummaryReport/">Order Summary Report</MenuItem>
                                    <MenuItem {...itemProps} component={Link} to="/OrderEntryReport/">Order Entry Report</MenuItem>
                                    <MenuItem {...itemProps} component={Link} to="/ProcessTrackingReport/">Process Tracking Report</MenuItem>
                                    <MenuItem {...itemProps} component={Link} to="/MappingErrorReport/">Mapping Error Report</MenuItem>
                                </List>
                            </ListItem>
                            <ListDivider />
                            <ListItem nested>
                                <List aria-label="Time travel">
                                    <MenuItem {...itemProps} component={Link} to="/MinuteByMinute/">Minute By Minute</MenuItem>
                                    <MenuItem {...itemProps} component={Link} to="/TnCompetitiveAnalysis/">TN Competitive Analysis</MenuItem>
                                    <MenuItem {...itemProps} component={Link} to="/TNHourlyData/">TN Hourly Data</MenuItem>
                                    <MenuItem {...itemProps} component={Link} to="/LastMinuteUnderservedEvents/">Last Minute Underserved Events</MenuItem>
                                </List>
                            </ListItem>
                        </Menu>
                    }
                >
                    Reports
                </MenuBarButton>
            </ListItem>
            <ListItem>
                <MenuBarButton
                    open={menuIndex === 4}
                    onOpen={() => {
                        setMenuIndex((prevMenuIndex) => (prevMenuIndex === null ? 4 : null));
                    }}
                    onKeyDown={createHandleButtonKeyDown(4)}
                    onMouseEnter={() => {
                        if (typeof menuIndex === 'number') {
                            setMenuIndex(4);
                        }
                    }}
                    ref={(instance) => {
                        menus.current[4] = instance;
                    }}
                    menu={
                        <Menu
                            onClose={() => {
                                menus.current[4]?.focus();
                            }}
                        >
                            <MenuItem {...itemProps} component={Link} to="/Email/">Email</MenuItem>
                            <MenuItem {...itemProps} component={Link} to="/Stealth/">Browser Stealth</MenuItem>
                            <ListDivider />
                            <MenuItem {...itemProps} component={Link} to="/CsvMerge/">Csv Merge</MenuItem>
                            <MenuItem {...itemProps} component={Link} to="/DynamicAdjustRule/">Dynamic Adjustment Rule</MenuItem>
                            <ListDivider />
                            <MenuItem {...itemProps} component={Link} to="/SmEventCheck/">Event Comparison Tool</MenuItem>
                            <MenuItem {...itemProps} component={Link} to="/ReceiptsCounter/">Receipts Counter</MenuItem>
                            <MenuItem {...itemProps} component={Link} to="/SearchTicketReceipt/">Ticket Search</MenuItem>
                            <MenuItem {...itemProps} component={Link} to="/AccountReport/">TM Processing Report</MenuItem>
                        </Menu>
                    }
                >
                    Tools
                </MenuBarButton>
            </ListItem>
            <ListItem>
                <MenuBarButton
                    open={menuIndex === 5}
                    onOpen={() => {
                        setMenuIndex((prevMenuIndex) => (prevMenuIndex === null ? 5 : null));
                    }}
                    onKeyDown={createHandleButtonKeyDown(5)}
                    onMouseEnter={() => {
                        if (typeof menuIndex === 'number') {
                            setMenuIndex(5);
                        }
                    }}
                    ref={(instance) => {
                        menus.current[5] = instance;
                    }}
                    menu={
                        <Menu
                            onClose={() => {
                                menus.current[5]?.focus();
                            }}
                        >
                            <MenuItem {...itemProps} component={Link} to="/PrimaryAccount/">Primary Accounts</MenuItem>
                            <MenuItem {...itemProps} component={Link} to="/VividPerformance/">Vivid Peformance Tracking</MenuItem>
                            <ListDivider title='AOT' />
                            <MenuItem {...itemProps} component={Link} to="/User/">Users</MenuItem>
                            <MenuItem {...itemProps} component={Link} to="/VividPerformance/">Vivid Peformance Tracking</MenuItem>
                            <ListDivider />
                            <MenuItem {...itemProps} component={Link} to="/UserDashboard/">Users Dashboard</MenuItem>
                            <MenuItem {...itemProps} component={Link} to="/WeeklyWorkTime/">Weekly Working Time</MenuItem>
                            <MenuItem {...itemProps} component={Link} to="/OrderAssignments/">Order Assignments</MenuItem>
                            <ListDivider title='AAT' />
                            <MenuItem {...itemProps} component={Link} to="/AAT_PrimaryAccounts/">AAT Primary Accounts</MenuItem>
                            <MenuItem {...itemProps} component={Link} to="/AAT_AccountAssignment/">AAT Account Assignment</MenuItem>
                        </Menu>
                    }
                >
                    Admins
                </MenuBarButton>
            </ListItem>
        </List>
    );
}