import { defaultExcludeForString, getExtractDate } from '../../../components/query-builder/querybuilder.config';
import { useDynamicRule } from './rule';
import { formatDateTime } from '../../../components/ex_dayjs';

function OrderRule(marketType, aatPAccounts) {
    const { validator, getRuleInfo } = useDynamicRule();
    const initialState = {
        hideColumns: { eventDate: false }
    };

    return getRuleInfo(getName, getColumnDefs, getConditionFields, getActionFields, initialState);

    function getName() {
        return 'OrderDynamicRule';
    }

    function getConditionFields() {
        const excludeConditionOperator = [...defaultExcludeForString, 'is_duplicated'];
        let ignoreMarekts = ['etix', 'exact'];
        let defaultMarketType = marketType.filter(x => !ignoreMarekts.some(y => y == x.name.toLowerCase()));
        return [
            { id: 'Venue', name: 'Venue', label: 'Venue', input: 'text', type: 'string', validator, excludes: excludeConditionOperator },
            { id: 'event_market', name: 'event_market', label: 'Event Market', defaultValue: 'accesso', values: defaultMarketType, input: 'select', type: 'string', validator, valueEditorType: 'select', excludes: excludeConditionOperator },
            { id: 'event_url', name: 'event_url', label: 'Event Url', input: 'text', type: 'string', validator, excludes: excludeConditionOperator },
            {
                id: 'EventDate', name: 'EventDate', label: 'EventDate', input: 'text', type: 'date',
                placeholder: 'yyyy-MM-dd, Now.AddDays(1), Now.AddMonths(1), Now.AddYears(1)',
                validator: (r) => {
                    if (Array.isArray(r.value)) {
                        for (let i = 0; i < r.value.length; i++) {
                            if (!r.value[i] || !getExtractDate(r.value[i])) return false;
                        }
                        return true;
                    } else return getExtractDate(r.value) != null;
                }
            },
            { id: 'Tags', name: 'Tags', label: 'Tags', validator, input: 'text', type: 'string', placeholder: 'default ignore "Dummy"', excludes: excludeConditionOperator }
        ];
    }

    function getActionFields() {
        const assignOp = [{ name: 'assign', label: 'assign' }];
        return [
            { id: 'LimitOrders', name: 'LimitOrders', label: 'Limit Orders', input: 'number', inputType: 'number', type: 'integer', defaultValue: 4, operators: assignOp, required: true },
            { id: 'LimitTickets', name: 'LimitTickets', label: 'Limit Tickets', input: 'number', inputType: 'number', type: 'integer', defaultValue: 8, operators: assignOp, required: true },
            { id: 'LimitByMarket', name: 'LimitByMarket', label: 'Limit By Market', input: 'number', inputType: 'number', type: 'integer', defaultValue: 1, placeholder: '1: Limit by market (across all venues, cumulatively), 0: Default - Individual Venues', operators: assignOp },
            { id: 'AllEvents', name: 'AllEvents', label: 'All Events', valueEditorType: 'checkbox', placeholder: '1: all event date, 0: each event date', defaultValue: 1, operators: assignOp },
            { id: 'StateBillingAddress', name: 'StateBillingAddress', label: 'State Billing Address', input: 'number', inputType: 'number', type: 'integer', placeholder: 'TRUE=1/FALSE=0', defaultValue: 0, operators: assignOp, validator },
            { id: 'SpecificAccounts', name: 'SpecificAccounts', label: 'Specific Accounts', placeholder: 'Accounts', input: 'text', type: 'string', valueEditorType: 'autocomplete', operators: assignOp, validator, defaultValue: [], values: aatPAccounts }
        ];
    }

    function getColumnDefs() {
        return [
            { field: 'primaryAccount', headerName: 'Primary Account', flex: 1 },
            { field: 'eventDate', headerName: 'Event Date', valueFormatter: params => { return formatDateTime(params.value); }, flex: 0.8 },
            { field: 'totalTickets', headerName: 'Total Tickets', flex: 0.5, type: 'number' },
            { field: 'totalOrders', headerName: 'Total Orders', flex: 0.5, type: 'number' },
            { field: 'limitTickets', headerName: 'Limit Tickets', flex: 0.5, type: 'number' },
            { field: 'limitOrders', headerName: 'Limit Orders', flex: 0.5, type: 'number' },
            { field: 'state', headerName: 'State', flex: 0.3 },
            { field: 'city', headerName: 'City', flex: 0.5 },
        ];
    }
}

export default OrderRule;