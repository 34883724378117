import { useRecordContext } from 'react-admin';

const DeliveryMethods = ["ETicket", "HardTicket", "Mobile", "FlashSeat", "DirectTransfer", "WillCall"];
export const DeliveryMethodFormatter = (props) => {
    const record = useRecordContext(props);
    if (!record.delivery_method) {
        return "Unknown";
    } else {
        return DeliveryMethods[record.delivery_method];
    }
}

DeliveryMethodFormatter.defaultProps = { label: 'Delivery method' };