import React, { useState } from "react";
import { useNotify } from "react-admin";
import { Grid, Typography } from "@mui/material";
import MDialog from "../../../../../components/controls/MDialog";
import LoadingButton from "../../../../../components/controls/LoadingButton";
import { List } from "@mui/icons-material";

const MLabel = ({ name, value }) => {
    return (
        <Grid container spacing={2} style={{ marginTop: '5px', marginBottom: '5px' }}>
            <Grid item xs={3}>
                <Typography style={{ fontWeight: 'bold', textAlign: "right" }}>{name}</Typography>
            </Grid>
            <Grid item xs={9} >
                <Typography>{value}</Typography>
            </Grid>
        </Grid>
    )
}

export default MoreDetailPopup = ({ vendor, dataProvider, data }) => {
    const { order } = data;
    const [item, setItem] = useState({});
    const [isOpen, setOpen] = useState(false);
    const notify = useNotify();

    async function doMore() {
        let response = await dataProvider.fetchData(vendor.controller, `GetCustomerDetail?orderNumber=${order.order_number}`);
        if (response) {
            let t = response.data;
            if (t) {
                if (!t.order) {
                    notify(`Order is not existing or error.`, { type: 'error' });
                    return;
                }
                var fullname = t.order.firstName;
                if (t.order.lastName) fullname += " " + t.order.lastName;
                var data = {
                    order_number: order.order_number,
                    event: t.order.event,
                    venue: t.order.venue,
                    notes: t.order.notes,
                    eticket: t.order.electronicDelivery,
                    airbill: t.order.airBillLink,
                    firstname: t.order.firstName,
                    lastname: t.order.lastName,
                    fullname: fullname,
                    email: t.order.email
                };
                setItem(data);
                setOpen(true);
            }
        }
    }

    return (
        <>
            <LoadingButton
                variant="contained"
                size="small"
                startIcon={<List />}
                promise={doMore}
            >
                More ...
            </LoadingButton>
            {isOpen && (
                <MDialog
                    className={'order-dialog'}
                    title={`Order #${order.order_number}`}
                    maxWidth={'sm'}
                    name={'isOpen'}
                    open={isOpen}
                    handleClose={() => setOpen(false)}
                >
                    <Grid container style={{ marginTop: '5px', marginBottom: '5px' }}>
                        <Typography style={{ fontWeight: 'bold', fontSize: 20 }}>Event info</Typography>
                    </Grid>
                    <MLabel name={"Event"} value={item.event} />
                    <MLabel name={"Venue"} value={item.venue} />
                    <Grid container style={{ marginTop: '20px', marginBottom: '5px' }}>
                        <Typography style={{ fontWeight: 'bold', fontSize: 20 }}>Customer info</Typography>
                    </Grid>
                    <MLabel name={"Name"} value={item.fullname} />
                    <MLabel name={"Email"} value={item.email} />
                </MDialog>
            )}
        </>
    )
}