import React, { useEffect, useState } from "react";
import RuleEditPopup from "../../../../csv-merge/components/RuleEditPopup";
import { loadResourceCSV } from "../../../../../utils/common";

const DMQPopup = ({ dataProvider, data, onClose }) => {
    const { order, dlgState } = data;
    const { dmq_isOpen } = dlgState;
    const [rule, setRule] = useState();
    const [source, setSource] = useState();

    useEffect(() => {
        loadResourceCSV(dataProvider, (response) => setSource(response));
    }, []);

    useEffect(() => {
        if (dmq_isOpen && order) {
            setRule({
                sources: source.filter(x => order.listing_accounts.some(y => y == x.Id || y == x.Name)).map(x => x.Name),
                event: order.event,
                venue: order.listing != null ? order.listing.venue : order.venue,
                quantity: order.listing != null ? order.listing.quantity : order.quantity,
                tags: [''],
                priority: 1,
                expiredDate: order.date,
                action: {
                    method: 'modify',
                    rules:
                        [{
                            id: 'Quantity',
                            field: 'Quantity',
                            type: 'string',
                            input: 'text',
                            operator: 'assign',
                            value: '=x-2'
                        }]
                }
            });
        }
    }, [dmq_isOpen, order]);

    return (
        <>
            {dmq_isOpen && rule &&
                <RuleEditPopup dataProvider={dataProvider}
                    onClose={onClose}
                    // reloadPage={}
                    data={{
                        dlgName: 'dmq_isOpen',
                        isOpenRule: dmq_isOpen,
                        sources: source,
                        ruleId: [-1, rule]
                    }} />
            }
        </>
    )
}

export default React.memo(DMQPopup);