import * as React from 'react';
import MAutoComplete from '../../../components/controls/MAutoComplete';

const getStyles = (tagName) => {
    switch (tagName) {
        case 'venue removal':
            return 'royalblue';
        case 'pricing error':
            return 'limegreen';
        case 'listing error':
            return 'gold';
        default:
            return '';
    }
}

const TagsAutoComplete = ({ label, onChange, ...props }) => {
    return (
        <MAutoComplete {...props}
            name="tags"
            label={label}
            onChange={onChange}
            choices={['Venue removal', 'Pricing error', 'Listing error', 'Banned']}
            getStyles={getStyles}
        />
    )
}

export default TagsAutoComplete;