import React, { useState } from "react";
import { Button } from '@mui/material';
import { Confirm, useNotify } from "react-admin";
import MBackdrop from "../../../components/controls/MBackdrop";

const SkipPricerAllButon = ({ params, dataProvider, selectedIds, reloadPage }) => {
    const notify = useNotify();

    const [state, setState] = useState({
        selectedIds: [],
        skip_isOpen: false,
        skip_sync_isLoading: false
    });

    const handleClose = () => {
        setState((prev) => ({ ...prev, skip_isOpen: false }));
    };

    const confirmSkipAll = () => {
        if (selectedIds?.length) {
            setState((prev) => ({ ...prev, skip_isOpen: true, selectedIds }));
        } else {
            notify(`Please select event to ${params.skipBtn}!!!`, { type: 'warning' });
        }
    };

    const handleSkipPricerAll = () => {
        handleClose();
        let data = params.data;
        var dataSelected = data.filter(x => selectedIds.find(y => y == x.id));
        dataSelected.forEach((e) => {
            e.EventID = e.id;
            e.sm_event_id = e.smEventID;
            e.sm_event_name = e.smEvent;
            e.sm_market_id = e.marketId;
            e.mapped = true;
        });
        if (dataSelected.length) {
            let isSkip = params.skipBtn == 'skip pricer';
            setState((prev) => ({ ...prev, skip_sync_isLoading: true }));
            dataProvider.postData(params.resource, `SkipPricers?isSkip=${isSkip ? true : false}`, dataSelected)
                .then((response) => {
                    if (response && response.data) {
                        if (response.data >= 0) {
                            notify(`All the events(${response.data}) were ${isSkip ? 'skipped' : 'enabled'}!!!`, { type: 'success' });
                            reloadPage();
                        } else notify(`${response.data}`, { type: 'error' });
                    }
                    setState((prev) => ({ ...prev, skip_sync_isLoading: false }));
                });
        }
    };

    return (
        <>
            <Button size="small" onClick={confirmSkipAll}>{params.skipBtn}</Button>
            <Confirm isOpen={state.skip_isOpen}
                title={`Are you sure to ${params.skipBtn} all the selected (${state.selectedIds.length}) records?`}
                onClose={handleClose}
                onConfirm={handleSkipPricerAll} />
            <MBackdrop isOpen={state.skip_sync_isLoading} message={'Skipping....'} />
        </>
    );
};

export default React.memo(SkipPricerAllButon);