import { fetchUtils } from 'react-admin';
import { stringify } from 'query-string';
import authProvider from './auth/authProvider';
import buildQuery from 'odata-query';
import { getDefaultUtcOffset, getRaStore, setRaStore } from './utils/common';
import { getCachedData, setCachedData } from './Caching';

const R2PrefixLength = 'https://r2.devsoft.workers.dev/'.length;
const apiUrl = process.env.REACT_APP_API_URL;
let jwt = undefined,
    fetchOptions = function (options) {
        if (!jwt) {
            jwt = authProvider.getJWT();
        }
        return Object.assign(
            {
                user: {
                    authenticated: true,
                    token: `Bearer ${jwt}`,
                },
            },
            options,
        );
    },
    httpClient = function (url, options) {
        if (!jwt) {
            jwt = authProvider.getJWT();
        }
        url = new URL(url, apiUrl.endsWith('/') ? apiUrl : apiUrl + '/').toString();
        return fetchUtils.fetchJson(url, fetchOptions(options));
    };
function convertToOdataValue(filterValue, operator) {
    if (typeof filterValue === 'string' && filterValue.match(/^\d{4}-\d{1,2}-\d{1,2}/)) {
        if (filterValue.endsWith('Z')) {
            return new Date(Date.parse(filterValue));
        }
        if (operator.endsWith('_lt') || operator.endsWith('_le')) {
            return new Date(Date.parse(filterValue + ' 23:59:59'));
        }
        return new Date(Date.parse(filterValue + ' 00:00:00'));
    }
    return filterValue;
}

function convertToODataFilter(filterName, filterValue) {
    //https://marmelab.com/react-admin/FilteringTutorial.html#saved-queries-let-users-save-filter-and-sort
    //[{ SomeProp: 1 }, { AnotherProp: 2 }, 'startswith(Name, "foo")'];
    let operator = '';
    if (filterName.lastIndexOf('_') > -1) {
        operator = filterName.slice(filterName.lastIndexOf('_')).toLowerCase();
        filterName = filterName.slice(0, -operator.length);
    }
    if (typeof filterValue === 'string') {
        filterValue = filterValue.trim();
        if (operator === '') operator = '_contains';
    }
    switch (operator) {
        // https://www.odata.org/documentation/odata-version-2-0/uri-conventions/
        case '_gt':
        case '_lt':
        case '_ge':
        case '_le':
        case '_eq':
        case '_ne':
        case '_in':
        case '_endswith':
        case '_startswith': {
            //https://github.com/techniq/odata-query#comparison-operators            
            let odataOperator = operator.slice(1);
            return {
                [filterName]: {
                    [odataOperator]: convertToOdataValue(filterValue, odataOperator)
                }
            };
        }
        case '_contains': {
            let odataOperator = operator.slice(1);
            filterValue = typeof (filterValue) === 'object' ? filterValue : [filterValue];
            return filterValue.map(element => {
                return {
                    [filterName]: {
                        [odataOperator]: convertToOdataValue(element, odataOperator)
                    }
                };
            });
        }
        case '_nin': {
            //https://learn.microsoft.com/en-us/odata/webapi/in-operator            
            return {
                not: {
                    [filterName]: {
                        in: filterValue
                    }
                }
            };
        }
        case '_ncontains': {
            let operator = 'contains';
            filterValue = typeof (filterValue) === 'object' ? filterValue : [filterValue];
            return filterValue.map(element => {
                return {
                    not: {
                        [filterName]: {
                            [operator]: convertToOdataValue(element, operator)
                        }
                    }
                };
            });
        }
        default:
            return {
                [filterName]: filterValue
            };
    }
}

function makeFulltextFilter(resource, q, meta) {
    if (meta && meta.q && meta.q.length) {
        // search q in multiple fields given by the meta.q
        return {
            or: meta.q.map((field) => convertToODataFilter(field, q)),
        };
    }
    throw new Error('Not implemented (no meta.q defined)');
    //return convertToODataFilter('q', q);
}

/**
 * Handle when click search
 * Using Caching the response data to avoid making redundant requests to the server
 * @param {*} resource 
 * @returns 
 */
function isSearch(resource, params) {
    var storeKey = `${resource}.isSearch`;
    var isSearch = getRaStore(storeKey);
    if (params.filter.isSearch === false) return false;
    if (isSearch === true) {
        setRaStore(storeKey, false);
        return true;
    }
    if (isSearch == null) return true;
    return false;
}

export const fetchFile = async (url, options) => {
    console.log(`Start: ` + new Date());
    const requestHeaders = fetchUtils.createHeadersFromOptions(fetchOptions(options));
    var resp = await fetch(url, { ...options, headers: requestHeaders });
    console.log(`End: ` + new Date());
    return resp;
};

export function buildQueryString(params, { top, skip, orderBy }) {
    ['_toggleFilter'].map((m) => {
        if (params.filter.hasOwnProperty(m)) delete params.filter[m];
    });
    //https://github.com/techniq/odata-query#implied-and-with-an-array-of-objects
    const filters = Object.entries(params.filter)
        .filter(([k, v]) => k !== 'q') // exclude 'q'
        .map(([filterName, filterValue], seq) => convertToODataFilter(filterName, filterValue)).flat();

    let qParam = '';
    if (params.filter.q) {        // has fulltext search ?
        if (params.meta.q) {
            filters.push(makeFulltextFilter(resource, params.filter.q, params.meta));
        }
        else {
            qParam = '&q=' + encodeURIComponent(params.filter.q);
        }
    }

    const count = true;
    const select = params.meta?.$select;
    const odataFilter = buildQuery({ count, select, top, skip, orderBy, filter: filters });
    return `${odataFilter}${qParam}`;
}

/**
 * Get CacheData filter, if not found then return an url
 * @param {*} resource 
 * @param {*} params 
 * @param {*} action 
 * @returns 
 */
function getFilter(resource, params, action = 'Filter') {
    if (action == 'Filter' && !isSearch(resource, params)) {
        var cachedData = getCachedData(resource);
        if (cachedData) return cachedData;
    }

    if (params.filter.sortPaging) {
        const { sort, page, perPage } = params.filter.sortPaging;
        params.sort = sort;
        params.pagination = { page, perPage };
        delete params.filter.sortPaging;
    }

    const { page, perPage } = params.pagination || { page: 1, perPage: 1000000 };
    const { field, order } = params.sort; // order is either 'DESC' or 'ASC'
    const top = perPage;
    const skip = perPage * (page - 1);
    let orderBy = [`${field} ${order === 'DESC' ? 'desc' : 'asc'}`];
    // multiple for orderby
    if (Array.isArray(field)) orderBy = field.filter(x => x).toString();

    const odataFilter = buildQueryString(params, { top, skip, orderBy });

    return `${apiUrl}/${resource}/${action}${odataFilter}`;
}

export const dataProvider = {
    getList: async (resource, params, action = 'Filter') => {
        const _filter = getFilter(resource, params, action);
        if (typeof _filter === 'string') { // that mean the _filter is url
            const resp = await httpClient(_filter);
            if (resp && resp.json) {
                var result = {
                    data: resp.json.items,
                    total: resp.json.total,
                };
                if (action == 'Filter') {
                    //Using Caching the response data to avoid making redundant requests to the server
                    setCachedData(resource, result);
                }
                return result;
            }
        }
        return _filter;
    },

    getOne: (resource, params) =>
        httpClient(`${apiUrl}/${resource}/GetOne/${params.id}`).then(({ json }) => ({
            data: json,
        })),

    getMany: async (resource, params) => {
        const query = {
            filter: JSON.stringify({ id: params.ids }),
        };
        const url = `${apiUrl}/${resource}?${stringify(query)}`;
        const resp = await httpClient(url);
        return { data: resp?.json?.items ?? resp };
    },

    getManyReference: (resource, params) => {
        const { page, perPage } = params.pagination;
        const { field, order } = params.sort;
        const query = {
            sort: JSON.stringify([field, order]),
            range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
            filter: JSON.stringify({
                ...params.filter,
                [params.target]: params.id,
            }),
        };
        const url = `${apiUrl}/${resource}?${stringify(query)}`;

        return httpClient(url).then(({ headers, json }) => ({
            data: json,
            total: parseInt(headers.get('content-range').split('/').pop(), 10),
        }));
    },

    update: (resource, params) =>
        httpClient(`${apiUrl}/${resource}/Update/${params.id}`, {
            method: 'PUT',
            body: JSON.stringify(params.data),
        }).then(({ json }) => ({ data: json })),

    updateMany: (resource, params) => {
        const query = {
            filter: JSON.stringify({ id: params.ids }),
        };
        return httpClient(`${apiUrl}/${resource}?${stringify(query)}`, {
            method: 'PUT',
            body: JSON.stringify(params.data),
        }).then(({ json }) => ({ data: json }));
    },

    create: (resource, params) =>
        httpClient(`${apiUrl}/${resource}/Create`, {
            method: 'POST',
            body: JSON.stringify(params.data),
        }).then(({ json }) => ({
            data: { ...params.data, id: json.id },
        })),

    delete: (resource, params) => {
        var urlDelete = `${apiUrl}/${resource}/Delete/${params?.id}`;
        if (typeof params === 'string') urlDelete = `${apiUrl}/${resource}/${params}`;
        return httpClient(urlDelete, {
            method: 'DELETE',
        }).then(({ json }) => ({ data: json }));
    },
    deleteMany: (resource, params) => {
        const query = {
            filter: JSON.stringify({ id: params.ids }),
        };
        return httpClient(`${apiUrl}/${resource}?${stringify(query)}`, {
            method: 'DELETE',
        }).then(({ json }) => ({ data: json }));
    },
    fetchR2: (r2Url) => {
        let r2Resource = r2Url.slice(R2PrefixLength);
        return httpClient(`${apiUrl}/r2/${r2Resource}`, {
            method: 'GET',
        });
    },
    fetchData: (resource, action) => {
        const url = `${apiUrl}/${resource}/${action}`;
        return httpClient(url, {
            method: 'GET',
        }).then(({ json }) => ({ data: json })).catch((error) => error);
    },
    fetch: httpClient,

    postData: (resource, action, data, method = 'POST') => {
        return httpClient(`${apiUrl}/${resource}/${action}`, {
            method: method,
            body: (data instanceof FormData) ? data : JSON.stringify(data),
        }).then(({ json }) => ({ data: json })).catch((error) => error);
    },
    /**
     * Export data with default get current search
     * otherwise => params: {...data}
     * @param {*} resource 
     * @param {*} params 
     * @param {*} exportFile 
     * @returns 
     */
    export: async (resource, params, exportFile) => {
        const { filename, columns } = exportFile;
        let _filter = getFilter(resource, params, 'Export');
        if (typeof _filter === 'string') { // that mean the _filter is url
            let utcOffset = getDefaultUtcOffset();
            let isCsv = filename.endsWith('.csv');
            return await fetchFile(_filter + `&utcOffset=${utcOffset}&columns=${encodeURIComponent(JSON.stringify(columns))}&isCsv=${isCsv}${exportFile.position ? `&position=${exportFile.position}` : ''}`);
        }
        return _filter;
    },
    currentUser: async () => {
        return await httpClient(`${apiUrl}/Common/GetCurrentUser`, {
            method: 'GET',
        }).then(_ => _.json);
    }
};
