import * as React from "react";
import { SelectArrayInput, SelectField, List, DateInput, TextInput, TextField, DateField, NumberField, Datagrid } from 'react-admin';
import { RaPagination } from "../../components/ra-list/RaList";
const DivvyCompanies = [
    { id: "Q29tcGFueTo2MDMwMQ==", name: "Song Technologies LLC" },
    { id: "Q29tcGFueTo2NjIyNQ==", name: "Song Tech Prepay" },
];
const postFilters = [
    <SelectArrayInput label="Company" source="companyId_in" choices={DivvyCompanies} placeholder="ANY" alwaysOn />,
    //<SelectArrayInput label="Exclude Company" source="companyId_nin" choices={DivvyCompanies} alwaysOn />, // demo 'not in'
    <DateInput label="Cleared At After" source="clearedAt_ge" alwaysOn />,
    <DateInput label="Cleared At Before" source="clearedAt_le" alwaysOn />,
    <TextInput source="lastFour" />,
    <TextInput source="cardName" />,
    <TextInput source="cleanedMerchantName" />,
];

const DivvytransactionList = () => (
    <List storeKey={false}
        disableSyncWithLocation
        exporter={false}
        pagination={<RaPagination />}
        perPage={25}
        sort={{ field: 'clearedAt', order: 'DESC' }}
        bulkActionButtons={false}
        filters={postFilters}
        filterDefaultValues={{ 'clearedAt_ge': new Date((new Date().getTime() - 15 * 24 * 60 * 60 * 1000)).toISOString() }}>
        <Datagrid >
            <SelectField source="companyId" choices={DivvyCompanies} translateChoice={false} />
            <TextField source="budget" />
            <TextField source="lastFour" />
            <TextField source="cardName" />
            <NumberField source="amount" options={{ style: 'currency', currency: 'USD' }} />
            <TextField source="cleanedMerchantName" />
            <DateField source="clearedAt" options={{ year: 'numeric', month: 'short', day: 'numeric' }} />
            <TextField source="type" />
            <TextField source="subtype" />
            <TextField source="userName" />
        </Datagrid>
    </List>
);
const DivvyPage = {
    name: 'DivvyTransaction',
    list: DivvytransactionList
}
export default DivvyPage;