import React, { useEffect, useRef, useState } from "react";
import { Button, Title, useDataProvider } from 'react-admin';
import { DataGrid } from '@mui/x-data-grid';
import { Grid, Typography, Box, Tooltip, CircularProgress } from '@mui/material';
import SyncIcon from '@mui/icons-material/Sync';
import RenderControls from "../../../components/ra-list/RenderControls";
import { getDefaultUtcOffset } from "../../../utils/common";
import { dayjs, formatDateTime } from "../../../components/ex_dayjs";

const RAReport = React.memo(() => {
    const [state, setState] = useState({
        loaded: true,
        index: -1,
        data: {},
        mapSources: [
            {
                index: 0, name: "topHighEvents", loaded: true
            }, {
                index: 1, name: "unprocesses", loaded: true
            }, {
                index: 2, name: "unshippeds", loaded: true
            }, {
                index: 3, name: "accountUsages", loaded: true
            }, {
                index: 4, name: "problemOrders", loaded: true
            }, {
                index: 5, name: "dropRates", loaded: true
            }, {
                index: 6, name: "autoprocesseds", loaded: true
            }, {
                index: 7, name: "autoshippeds", loaded: true
            }, {
                index: 8, name: "highQties", loaded: true
            }, {
                index: 9, name: "unassignedBlumenthal", loaded: true
            }, {
                index: 10, name: "supportOrders", loaded: true
            }, {
                index: 11, name: "mappingEvents", loaded: true
            }
        ]
    });

    let currentDate = new Date();
    const [defaultValues] = useState({
        order_date: [currentDate, currentDate]
    });

    const dataProvider = useDataProvider();
    const handleSearch = (params) => {
        let mapSources = state.mapSources;;
        mapSources[state.index].loaded = false;
        setState({ ...state, mapSources: mapSources });

        let fDate = params.order_date_ge ?? dayjs(currentDate).format('YYYY-MM-DD') + ' 00:00:00Z';
        let tDate = params.order_date_le ?? dayjs(currentDate).format('YYYY-MM-DD') + ' 23:59:59Z';
        let query = `Index?from=${fDate}&to=${tDate}` + `&utcOffset=` + getDefaultUtcOffset() + `&index=${state.index}`;
        dataProvider
            .fetchData('OrderSummaryReport', query)
            .then((response) => {
                let newData = state.data, mapSources = state.mapSources;
                newData[state.curSource] = response.data[state.curSource];
                mapSources[state.index].loaded = true;
                setState({ ...state, mapSources: mapSources, data: newData });
            });
    }

    const refFilter = useRef(null);
    const handleSubSearch = (e, props) => {
        setState({ ...state, index: props.index, curSource: props.id });
        setTimeout(() => refFilter.current.click());

        let offsetTop = e.currentTarget.offsetTop;
        setTimeout(() => {
            window.scrollTo({
                top: offsetTop,
                behavior: 'smooth',
            });
        }, 2000);
    }

    const MyTooltipHeader = (params) => {
        return (
            <Tooltip title={params.title}>
                <div>{params.colDef.headerName}</div>
            </Tooltip>
        );
    };

    const MyDataGrid = React.memo((props) => {
        const { id, sortArr, title, index } = props;
        const [sortModel, setSortModel] = useState(sortArr);

        const handleSortModelChange = (newModel) => {
            setSortModel(newModel);
        };

        return (
            <Box sx={{
                '& .MuiDataGrid-root.hide-pagination .MuiDataGrid-footerContainer': {
                    display: "none"
                },
                '& .MuiDataGrid-cell.text-red': {
                    color: 'red',
                },
                '& .MuiDataGrid-cell.text-warn': {
                    color: 'orange',
                },
                width: '100%', minHeight: 150
            }}>
                <Typography display="inline" variant="h6" gutterBottom flex="1" fontWeight="medium">{title}</Typography>
                {props.subtitle && <Typography display="inline" variant="subtitle2"> {props.subtitle}</Typography>}
                <Button
                    style={{
                        margin: 5,
                        marginTop: 0
                    }}
                    startIcon={!state.mapSources[index].loaded ? (<CircularProgress size={20} />) : (<SyncIcon />)}
                    onClick={(e) => handleSubSearch(e, props)} size="small"></Button>
                <DataGrid
                    {...props}
                    density="compact"
                    autoHeight={true}
                    sortModel={sortModel}
                    onSortModelChange={handleSortModelChange}
                    // Add a unique id prop to each DataGrid component
                    id={id}
                    disableColumnMenu={true}
                    className={props.hideFooterPagination && "hide-pagination"}
                />
            </Box>
        );
    })

    return (
        <Box>
            <Title title={"Order summary report"}></Title>
            <Grid container columnSpacing={2}>
                <Grid item md={12}>
                    <RenderControls
                        refFilter={refFilter}
                        controls={[
                            { component: 'daterange', name: 'order_date', label: 'Order date (from)', xs: 3 }
                        ]}
                        defaultValues={defaultValues}
                        onSearch={handleSearch}
                        hideFilter
                        disabled={!state.loaded}
                    />
                </Grid>
                <Grid item md={6}>
                    {
                        /* Todays High Event */
                        <MyDataGrid
                            index={state.mapSources[0].index}
                            id={state.mapSources[0].name}
                            title="Todays High Event"
                            rows={state?.data?.topHighEvents || []}
                            columns={[
                                { field: 'eventName', headerName: 'Event Name', flex: 1 },
                                { field: 'quantity', headerName: 'Quantity', type: 'number', width: 120 }
                            ]}
                            getRowId={(r) => r.eventName}
                            sortArr={[{ field: 'quantity', sort: 'desc' }]}
                            pageSizeOptions={[5, 10, 30]}
                            initialState={{
                                pagination: {
                                    paginationModel: {
                                        pageSize: 10,
                                    },
                                },
                            }}
                        />
                    }
                    {
                        /* Unprocessed Orders */
                        <MyDataGrid
                            index={state.mapSources[1].index}
                            id={state.mapSources[1].name}
                            title="Unprocessed Orders"
                            rows={state?.data?.unprocesses || []}
                            columns={[
                                {
                                    field: 'marketName', headerName: 'Market', flex: 1,
                                    cellClassName: (params) => {
                                        return params.row?.marketId > 990 ? "text-warn" : "";
                                    },
                                },
                                {
                                    field: 'total', headerName: 'Total',
                                    renderHeader: (params) => <MyTooltipHeader {...params} title="↓ total # of unprocessed orders" />,                                   
                                    type: 'number', width: 100
                                },
                                {
                                    field: 'priorities', headerName: 'Priorities',
                                    renderHeader: (params) => <MyTooltipHeader {...params} title="↓ events with red or yellow ihd" />,
                                    type: 'number', width: 120
                                }
                            ]}
                            getRowId={(r) => r.marketName}
                            // sortArr={[{ field: 'priorities', sort: 'desc' }]}
                            hideFooterPagination={true}
                        />
                    }
                    {
                        /* Unshipped Orders */
                        <MyDataGrid
                            index={state.mapSources[2].index}
                            id={state.mapSources[2].name}
                            title="Unshipped Orders"
                            rows={state?.data?.unshippeds || []}
                            columns={[
                                { field: 'marketName', headerName: 'Market', flex: 1 },
                                {
                                    field: 'overdue', headerName: 'Overdue',
                                    cellClassName: "text-red",
                                    renderHeader: (params) => <MyTooltipHeader {...params} title="↓# orders in available unshipped & delayed unshipped that have inhand dates before today's date." />,
                                    type: 'number', width: 80
                                },
                                {
                                    field: 'priorities', headerName: 'Priorities',
                                    renderHeader: (params) => <MyTooltipHeader {...params} title="↓events within the next 3 days" />,
                                    type: 'number', width: 80
                                },
                                {
                                    field: 'available', headerName: 'Available', type: 'number',
                                },
                                { field: 'delayed', headerName: 'Delayed', type: 'number', width: 80 },
                                { field: 'total', headerName: 'Total', type: 'number', width: 80 }
                            ]}
                            getRowId={(r) => r.marketName}
                            sortArr={[{ field: 'total', sort: 'desc' }]}
                            hideFooterPagination={true}
                        />
                    }

                    {
                        /* Account Usage */
                        <MyDataGrid
                            index={state.mapSources[3].index}
                            id={state.mapSources[3].name}
                            title="Account Usage"
                            subtitle="(↓ top 5 most used purchasing accounts for 3tm (1st of the month - today's date))"
                            rows={state?.data?.accountUsages || []}
                            columns={[
                                { field: 'account', headerName: 'Account', flex: 1 },
                                {
                                    field: 'total', headerName: 'Total', type: 'number', width: 100
                                }
                            ]}
                            getRowId={(r) => r.account}
                            sortArr={[{ field: 'total', sort: 'desc' }]}
                            hideFooterPagination={true}
                        />
                    }
                </Grid>
                <Grid item md={6}>
                    {
                        /* Problem Orders */
                        <MyDataGrid
                            index={state.mapSources[4].index}
                            id={state.mapSources[4].name}
                            title="Problem Orders"
                            rows={state?.data?.problemOrders || []}
                            columns={[
                                { field: 'marketName', headerName: 'Market', flex: 1 },
                                { field: 'overdue', headerName: 'Overdue', cellClassName: "text-red", type: 'number', width: 100 },
                                { field: 'priorities', headerName: 'Priorities', type: 'number', width: 100 },
                                { field: 'currentPO', headerName: 'Current PO', type: 'number', width: 100 },
                                { field: 'total', headerName: 'Total', type: 'number', width: 100 }
                            ]}
                            getRowId={(r) => r.marketName}
                            sortArr={[{ field: 'total', sort: 'desc' }]}
                            hideFooterPagination={true}
                        />
                    }

                    {
                        /* Support Orders */
                        <MyDataGrid
                            index={state.mapSources[10].index}
                            id={state.mapSources[10].name}
                            title="Order Support"
                            rows={state?.data?.supportOrders || []}
                            columns={[
                                { field: 'marketName', headerName: 'Market', flex: 1 },
                                { field: 'overdue', headerName: 'Overdue', cellClassName: "text-red", type: 'number', width: 100 },
                                { field: 'priorities', headerName: 'Priorities', type: 'number', width: 100 },
                                { field: 'currentPO', headerName: 'Current PO', type: 'number', width: 100 },
                                // { field: 'total', headerName: 'Total', type: 'number', width: 100 }
                            ]}
                            getRowId={(r) => r.marketName}
                            sortArr={[{ field: 'total', sort: 'desc' }]}
                            hideFooterPagination={true}
                        />
                    }

                    {
                        /* Drop Rates */
                        <MyDataGrid
                            index={state.mapSources[5].index}
                            id={state.mapSources[5].name}
                            title="Drop Rates"
                            subtitle="(↓ stats from 1st of the month - today's date)"
                            rows={state?.data?.dropRates || []}
                            columns={[
                                { field: 'market', headerName: 'Market', flex: 1 },
                                { field: 'drops', headerName: 'Drops', type: 'number', width: 100 },
                                { field: 'total', headerName: 'Total Orders', type: 'number', width: 100 },
                                {
                                    field: 'percent', headerName: '%',
                                    cellClassName: (params) => {
                                        return params.value > 1.5 ? "text-red" : ""
                                    },
                                    valueFormatter: (params) => {
                                        const value = parseFloat(params.value).toFixed(2);
                                        return `${value}`;
                                    },
                                    type: 'number', width: 100
                                }
                            ]}
                            getRowId={(r) => r.market}
                            sortArr={[{ field: 'percent', sort: 'desc' }]}
                            hideFooterPagination={true}
                        />
                    }

                    {
                        /* Autoprocessed */
                        <MyDataGrid
                            index={state.mapSources[6].index}
                            id={state.mapSources[6].name}
                            title="Autoprocessed"
                            subtitle="(↓# orders autoprocessed by each ap, today)"
                            rows={state?.data?.autoprocesseds || []}
                            columns={[
                                { field: 'account', headerName: 'Account', flex: 1 },
                                {
                                    field: 'purchased', headerName: 'Purchased', type: 'number', cellClassName: (params) => {
                                        return params.value == 0 ? "text-red" : ""
                                    }, width: 120
                                }
                            ]}
                            getRowId={(r) => r.account}
                            sortArr={[{ field: 'purchased', sort: 'desc' }]}
                            hideFooterPagination={true}
                        />
                    }
                    {
                        /* Autoshipped */
                        <MyDataGrid
                            index={state.mapSources[7].index}
                            id={state.mapSources[7].name}
                            title="Autoshipped"
                            subtitle="(↓# orders autoshipped by each ap, today)"
                            rows={state?.data?.autoshippeds || []}
                            columns={[
                                { field: 'market', headerName: 'Market', flex: 1 },
                                { field: 'shipped', headerName: 'Shipped', type: 'number', width: 120 }
                            ]}
                            getRowId={(r) => r.market}
                            sortArr={[{ field: 'shipped', sort: 'desc' }]}
                            hideFooterPagination={true}
                        />
                    }
                </Grid>
                <Grid item md={12}>
                    {
                        /* High Qty Outstanding  */
                        <MyDataGrid
                            index={state.mapSources[8].index}
                            id={state.mapSources[8].name}
                            title="High Qty Outstanding"
                            subtitle="(↓ top 5 events with most orders (for events occuring today or in the future only, exclude past events))"
                            rows={state?.data?.highQties || []}
                            columns={[
                                { field: 'eventName', headerName: 'Event Name', flex: 1 },
                                { field: 'venueName', headerName: 'Venue Name', flex: 1 },
                                {
                                    field: 'eventDate', headerName: 'Date', type: 'Date',
                                    valueFormatter: (params) => formatDateTime(params.value),
                                    flex: 1
                                },
                                { field: 'market', headerName: 'Event Market', width: 150 },
                                { field: 'quantity', headerName: 'Quantity', type: 'number', width: 120 }
                            ]}
                            getRowId={(r) => r.eventName + r.venueName + r.eventDate + r.market}
                            sortArr={[{ field: 'quantity', sort: 'desc' }]}
                            hideFooterPagination={true}
                        />
                    }
                </Grid>
                <Grid item md={6}>
                    {
                        /* Unassigned Blumenthal */
                        <MyDataGrid
                            index={state.mapSources[9].index}
                            id={state.mapSources[9].name}
                            title="Unassigned Blumenthal"
                            subtitle="(↓# of unprocessed with the 'Blumenthal - SPECIAL ACCTS REQ'D' tag, with a null primary account)"
                            rows={state?.data?.unassignedBlumenthal || []}
                            columns={[
                                { field: 'marketName', headerName: 'Market', flex: 1 },
                                { field: 'unassigned', headerName: 'Unassigned', type: 'number', width: 150 }
                            ]}
                            getRowId={(r) => r.marketName}
                            sortArr={[{ field: 'unassigned', sort: 'desc' }]}
                            hideFooterPagination={true}
                        />
                    }
                </Grid>
                <Grid item md={6}>
                    {
                        /* Matching Events */
                        <MyDataGrid
                            index={state.mapSources[11].index}
                            id={state.mapSources[11].name}
                            title="Matching Events"
                            subtitle="(↓# this from the event matcher, score 3.5-5, excluding the 'skip, manual review' tags)"
                            rows={state?.data?.mappingEvents || []}
                            columns={[
                                {
                                    field: 'marketName', headerName: 'Market', flex: 1
                                },
                                {
                                    field: 'total', headerName: 'Queue',
                                    type: 'number', width: 100
                                }, {
                                    field: 'manualReview', headerName: 'Manual Review',
                                    type: 'number', width: 150
                                }
                            ]}
                            getRowId={(r) => r.marketName}
                            hideFooterPagination={true}
                        />
                    }
                </Grid>
            </Grid>
        </Box>
    );
});

const RAPage = {
    name: 'OrderSummaryReport',
    list: RAReport
}
export default RAPage;