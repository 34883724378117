import * as React from 'react';
import { useState, useRef, useEffect } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import RuleCreator from './RuleCreator';
import { Stack } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import SendIcon from '@mui/icons-material/Send';
import { useNotify, Confirm } from 'react-admin';
import { dataProvider } from '../../../DataProvider';
import { LinearProgress } from '@mui/material';
import { reloadPage } from '../../../utils/common';

export default function ActionForm({ isEdit, open, onClose, editData, marketType, delivery, account ,refbtnFilter}) {
    const [openConfirm, setOpenConfirm] = useState(false);
    const [valid, setValid] = useState(true);
    const [dataUpdate, setDataUpdate] = useState(null);
    const [loading, setLoading] = useState(false);
    const [confirmData, setConfirmData] = useState(null);
    const [data, setData] = useState(editData);
    const actionQuery = useRef();
    const conditionQuery = useRef();
    const notify = useNotify();
    const handleClose = () => {
        onClose();
    };
    useEffect(() => {
        setData(editData);
        return () => {
            setData(null);
          };
    }, [editData]);

    const handleDataChange = (e) => {
        setValid(true);
        setData(preData => ({ ...preData, name: e.target.value }));
    }

    const handleApply = (actionType) => {
        const dataUpdate = getData();
        if (!dataUpdate || !dataUpdate.action || !dataUpdate.condition) return;
        dataUpdate.preferSQLQuery = conditionQuery.current.getValues('sql') || '';
        dataUpdate.isRemove = actionType === 'Clean' ? true : false;
        setLoading(true);
        setData(prev => ({...prev, action: dataUpdate.action, condition: dataUpdate.condition}));

        dataProvider.postData('OrderAdjustRule', 'GetCount', dataUpdate)
            .then((response) => {
                if (response && response.data > 0) {
                    setDataUpdate(dataUpdate);
                    setOpenConfirm(true);
                    setConfirmData({
                        title: 'Update Rule',
                        content: `This rule will affect to ${response.data} orders. Are you sure to continue?`,
                        action: actionType
                    })
                } else if(response.name === 'TypeError' || response.status === 500){
                    notify("Something went wrong", { type: 'error', autoHideDuration: 5000 });
                }
                 else {
                    notify('No order affected', { type: 'info', autoHideDuration: 5000 });
                }
            })
            .catch(e => {
                notify(`Something went wrong. \nError_INFO:::${e}`, { type: 'error', autoHideDuration: 5000 });
            })
            .finally(() => {
                setLoading(false);
            });
    }

    const handleConfirm = (action) => {
        if (action === 'DELETE') {
            const deleteData = {
                id: data?.id || '0'
            }
            setLoading(true);
            dataProvider.postData('OrderAdjustRule', 'Save', deleteData)
                .then((response) => {
                    if (response && response.data) {
                        notify('Save Success', { type: 'success', autoHideDuration: 5000 });
                        onClose();
                        reloadPage('OrderAdjustRule', refbtnFilter);
                    } else {
                        notify("Fail to save", { type: 'error', autoHideDuration: 5000 });
                    }
                })
                .catch(e => {
                    notify(`Fail to save.\nError_INFO:::${e}`, { type: 'error', autoHideDuration: 5000 });
                })
                .finally(() => {
                    setLoading(false);
                    setOpenConfirm(false);
                });
        } else {
            setLoading(true);
            dataProvider.postData('OrderAdjustRule', 'Apply', dataUpdate)
                .then((response) => {
                    if (response && response.data) {
                        notify('Update Success', { type: 'success', autoHideDuration: 5000 });
                    } else {
                        notify(`Fail to update \nError_INFO:::${response}`, { type: 'error', autoHideDuration: 5000 });
                    }
                })
                .catch(e => {
                    notify(`Something went wrong. \nError_INFO:::${e}`, { type: 'error', autoHideDuration: 5000 });
                })
                .finally(() => {
                    setLoading(false);
                    setOpenConfirm(false);
                });
        }
    }

    const handleSave = () => {
        const data = getData();
        if (data === null) return;
        setLoading(true);
        dataProvider.postData('OrderAdjustRule', 'Save', data)
            .then((response) => {
                if (response && response.data) {
                    notify('Update Success', { type: 'success', autoHideDuration: 5000 });
                    onClose();
                    reloadPage('OrderAdjustRule', refbtnFilter);
                } else {
                    notify("Fail to update", { type: 'error', autoHideDuration: 5000 });
                }           
            })
            .catch(_ => {
                notify("Something went wrong", { type: 'error', autoHideDuration: 5000 });
            })
            .finally(() => {
                setLoading(false);
                setOpenConfirm(false);
            });
    }

    const handleDelete = () => {
        setOpenConfirm(true);
        setConfirmData({
            title: 'Delete Rule',
            content: `You are about to delete the rule. Are you sure?`,
            action: 'DELETE'
        })
    }

    const getData = () => {
        if (!data?.name || data?.name === '') {
            setValid(false);
            return null;
        }

        var _action = actionQuery.current.getValues('json');
        var _condition = conditionQuery.current.getValues('json');

        if (!_action) notify(`Invalid actions`, { type: 'error' });
        if (!_condition) notify(`Invalid conditions`, { type: 'error' });

        return {
            id: data?.id || '0',
            name: data?.name || '',
            action: _action,
            condition: _condition,
            createdby: data?.createdby || '',
            modified: data?.modified
        }
    }
    return (
        <div>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
                fullWidth={true}
                scroll={'paper'}
                maxWidth='md'
            >
            {loading && <LinearProgress color='secondary' />}        

                <DialogTitle id="scroll-dialog-title">
                    {isEdit ? `Update Rule - #${editData.id}` : 'Create New Rule'}
                </DialogTitle>
                <DialogContent dividers={scroll === 'paper'}>
                    <RuleCreator 
                    onDataChange={handleDataChange} 
                    isEdit={isEdit} editData={data} 
                    conditionQueryRef={conditionQuery} 
                    actionQueryRef={actionQuery} 
                    valid={valid} 
                    marketType={marketType}
                    delivery={delivery}
                    account={account}
                    />
                </DialogContent>
                <DialogActions >
                    <Stack width='100%' direction={{ xs: 'column', sm: 'row' }}
                        justifyContent="space-between">
                        <Stack width='70%' direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                            <Button disabled={loading} startIcon={<SendIcon />} variant="contained" onClick={handleSave}>Save</Button>
                            <Button disabled={loading} variant="contained" onClick={() => handleApply('Apply')}>Apply</Button>
                            <Button disabled={loading} variant="contained" onClick={() => handleApply('Clean')}>Clean</Button>
                        </Stack>
                        <Stack >
                            <Button disabled={loading} variant="contained" color="warning" startIcon={<DeleteIcon />} onClick={handleDelete}>Delete</Button>
                        </Stack>
                    </Stack>
                </DialogActions>
            </Dialog>
            <Confirm isOpen={openConfirm} title={confirmData?.title || ''} content={confirmData?.content || ''} onConfirm={() => handleConfirm(confirmData?.action || '')} onClose={() => setOpenConfirm(false)} />
        </div>
    );
}