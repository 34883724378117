import { Box } from "@mui/system";
import React, { useEffect, useMemo, useRef, useState } from "react";
import AgGrid from "../../../../components/ag-grid/ag-grid";
import { Button, Checkbox, FormControlLabel, IconButton, LinearProgress, TextField, Tooltip } from "@mui/material";
import { Add, Remove, Lock } from "@mui/icons-material";
import { dayjs as mydayjs, toLocalTime } from "../../../../components/ex_dayjs";
import { useNotify } from "react-admin";

const lockId = 'lock';
const defaultPinTop = [
    { id: lockId, post: "Pinned Notes", empty: null, pinTag: null }
]
const OrderNotesDetails = (props) => {
    var { dataProvider, vendor, callback, notes, customer, order, cad2usdNote } = props;  
    const [showSystemNotes, setShowSystemNotes] = useState(false);
    const [pinnedTopRowData, setPinnedTopRowData] = useState(defaultPinTop);
    const newNoteRef = useRef(null);
    const [newNoteRequired, setNewNoteRequired] = useState();
    const notify = useNotify();

    useEffect(() => {
        if (notes && order) {
            let listUnpinned = notes.filter(x => x.note_type < 0);
            if (listUnpinned.length) {
                listUnpinned = listUnpinned.map(x => ({ ...x, id: '***' }));
            }
            let newPinnedTopRows = [...defaultPinTop, ...listUnpinned];
            //Showing customer infor for DT, FS
            if (order && customer && (order.delivery_method == 4 || order.delivery_method == 5)) {
                newPinnedTopRows.unshift({ id: '***', post: [`Customer Name: ${customer.name}`, `Email: ${customer.email}`] });
            }
            setPinnedTopRowData(newPinnedTopRows);
        }
    }, [notes, order, customer]);

    useEffect(() => {
        if (!!cad2usdNote) {
            newNoteRef.current.value = cad2usdNote;
            handleAddNote();
        }
    }, [cad2usdNote])

    const postComponent = (props) => {
        const { data, value } = props;
        return (<span style={{ whiteSpace: 'wrap' }}>
            <strong>{data?.author}:&nbsp;</strong>
            {value} -&nbsp;
            <Tooltip title={toLocalTime(data?.utc_posted_on_str).format('MMM DD, YYYY hh:mm A')}>
                <span style={{ color: '#85C1E9' }}>{mydayjs.utc(data?.utc_posted_on_str).fromNow()}</span>
            </Tooltip>
        </span>)
    }
    const systemNotesComponent = (props) => {
        return (
            <FormControlLabel
                style={{ fontSize: '10px', fontWeight: 'bold' }}
                control={<Checkbox onChange={handleShowSystemNote} checked={showSystemNotes} />}
                label="Show System Notes Also"
            />
        )
    }
    const pinNoteComponent = (props) => {
        const { data } = props;
        let pinned = data.note_type >= 0;
        const onPin = () => {
            //Unify note_type (0 | -99: UserNote)
            let noteType = parseInt(data.note_type);
            if (noteType == -99) noteType = 0;
            else if (noteType == 0) noteType = 99;

            dataProvider
                .postData(vendor.controller, 'Note', {
                    note_id: data.note_id,
                    order_id: order.id,
                    order_number: order.order_number,
                    note_type: -1 * noteType
                })
                .then(rs => {
                    if (rs.data) {
                        callback(rs.data);
                        notify('Your note for order #' + order.order_number + (noteType > 0 ? ' pinned' : ' unpinned'), { type: 'success', autoHideDuration: 2000 });
                    }
                })
                .catch(error => {
                    notify(`Add note failed: Status ${error.status}, Error ${error}`, { type: 'error', autoHideDuration: 3000 })
                });
        }
        return (
            <>
                {data.id != lockId ?
                    <IconButton color="primary" title={pinned ? 'Pin' : 'Unpin'} onClick={onPin}> {pinned ? <Add /> : <Remove style={{ color: 'red' }} />}</IconButton>
                    : <></>
                }
            </>
        )
    }

    const pinnedBottomRowData = useMemo(() => {
        return [{ id: 99, post: "", empty: null, pinTag: null },];
    }, []);

    const handleShowSystemNote = (props) => {
        setShowSystemNotes(props.target.checked);
    }

    const handleAddNote = () => {
        let newNote = newNoteRef.current.value.trim();
        if (!newNote) {
            setNewNoteRequired('This field cannot be null or contain only whitespace.');
            return;
        }
        setNewNoteRequired(null);
        newNoteRef.current.value = '';
        dataProvider
            .postData(vendor.controller, 'Note', {
                order_id: order.id,
                order_number: order.order_number,
                post: newNote
            })
            .then(rs => {
                if (rs.data) {
                    newNoteRef.current.value = '';
                    callback(rs.data);
                    notify('Your note for order #' + order.order_number + ' added.', { type: 'success', autoHideDuration: 2000 });
                }
            })
            .catch(error => {
                notify(`Add note failed: Status ${error.status}, Error ${error}`, { type: 'error', autoHideDuration: 3000 });
            });
    }
    //declare columns in aggrid
    const columnDefs = [
        {
            field: 'id', headerName: '#', width: "30px", padding: '0px',
            cellRendererSelector: (params) => {
                if (params.node.rowPinned && params.node.rowTop == 0) {
                    if (params.node.rowPinned == 'top') {
                        if (params.node.data.id == lockId) return renderLock();
                    } else return { component: () => (<span style={{ fontSize: 'large' }}>$</span>) };
                } else {
                    if (params.node.data.id == lockId) return renderLock();
                    // rows that are not pinned don't use any cell renderer
                    return undefined;
                }

                function renderLock() {
                    return {
                        component: () => <Lock style={{ fontSize: 'bold', textAlign: 'left', verticalAlign: 'middle', color: '#85C1E9' }} />
                    };
                }
            }
        },
        {
            field: 'post', headerName: 'Note', flex: 1, autoHeight: true, colSpan: () => 2,
            cellRenderer: postComponent,
            cellRendererSelector: (params) => {
                if (params.node.rowPinned && params.node.rowTop == 0) {
                    if (params.node.rowPinned == 'top') {
                        return renderPinnedTop(params.node.data.id == lockId ? null : { color: 'red' });
                    } else {
                        return {
                            component: () => (<>
                                <TextField sx={{ width: '85%' }} variant='standard' placeholder="New note for this order" multiline rows={2}
                                    inputRef={newNoteRef}
                                    error={!!newNoteRequired}
                                // helperText={newNoteRequired}
                                />
                                <Button variant="contained" size="small" sx={{ marginLeft: '5px', marginTop: '10px' }} onClick={handleAddNote}>Add</Button>
                            </>),
                        };
                    }
                } else {
                    if (params.node.data.id == lockId) return renderPinnedTop();
                    return undefined;
                }

                function renderPinnedTop(style = {}) {
                    return {
                        component: () => {
                            let newStyle = { fontSize: 'bold', color: '#85C1E9', overflow: 'hidden', ...style };
                            if (Array.isArray(params.node.data.post)) {
                                let length = params.node.data.post.length;
                                return (
                                    <>
                                        {params.node.data.post.map((p, index) => (
                                            <span key={index} style={{ ...newStyle, float: index == length - 1 ? 'right' : 'left' }}>
                                                {p}
                                            </span>
                                        ))}
                                    </>
                                )
                            }
                            return <span style={newStyle}>{params.node.data.post}</span>
                        }
                    };
                }
            }
        },
        {
            field: 'empty', headerAlign: 'right', width: "250px", type: 'rightAligned', headerComponent: systemNotesComponent,
            cellRendererSelector: (params) => {
                if (params.node.rowPinned && params.node.rowTop == 0) {
                    return { component: () => null };
                } else return undefined;
            }
        },
        {
            field: 'pintag', headerName: '', width: "40px", cellStyle: { textAlign: 'left' },
            cellRenderer: pinNoteComponent,
            cellRendererSelector: (params) => {
                if (params.node.rowPinned && params.node.rowTop == 0) {
                    return { component: () => null };
                } else {
                    return undefined;
                }
            }
        }
    ];
    const defaultColDef = useMemo(() => {
        return {
            cellStyle: { 'fontSize': '14px', 'alignItem': 'center' },
        };
    }, []);

    var gridOptions = {
        getRowStyle: ({ node }) => {
            if (node.rowPinned == 'top') {
                if (node.data.note_type < 0) {
                    return {
                        color: 'red',
                        backgroundColor: '#f0f0c9' //'lightgoldenrodyellow' for light mode
                    };
                }
            }
            return 0;
        }
    };

    return (
        <Box sx={{ height: 450, width: '100%' }}>
            {notes == null && <LinearProgress color='secondary' />}
            {pinnedTopRowData && (
                <AgGrid //https://www.ag-grid.com/javascript-data-grid/grid-options
                    className='order-notes'
                    applyTransaction
                    columnDefs={columnDefs}
                    rowData={(showSystemNotes ? notes?.filter(x => x.note_type >= 0) : notes?.filter(x => x.note_type == 0))?.map((x, index) => ({ ...x, id: index + 1 }))}
                    defaultColDef={defaultColDef}
                    gridHeight={'50vh'}
                    hidePaging={true}
                    rowSelection={'single'}
                    gridOptions={gridOptions}
                    pinnedTopRowData={pinnedTopRowData}
                    pinnedBottomRowData={pinnedBottomRowData}
                    getRowHeight={params => params.node.rowPinned == 'bottom' ? 65 : 42} //set row height for bottom pinned row
                />
            )}
        </Box>
    )
};
export default OrderNotesDetails;