const DeliveryMethodEnum = [
    { id: '0', value: 'ETicket', name: "E-Ticket" },
    { id: '1', value: 'HardTicket', name: "Hard Ticket" },
    { id: '2', value: 'Fedex', name: "Fedex" },
    { id: '3', value: 'Mobile', name: "Mobile" },
    { id: '4', value: 'FlashSeat', name: "Flash Seat" },
    { id: '5', value: 'DirectTransfer', name: "Direct Transfer" },
    { id: '6', value: 'WillCall', name: "Will Call" }
];

const ActionEnum = [
    { id: -1, name: 'ACTIVE' },
    { id: ' ', name: 'ANY' },
    { id: 0, name: 'NO CHANGES' },
    { id: 1, name: 'REMOVED' },
    { id: 2, name: 'UPDATED' },
    { id: 3, name: 'DELISTED' },
];

const ExchangeMarket = [
    { id: 1, name: 'Vivid' },
    { id: 2, name: 'Ticket Network' },
    // { id: 3, name: 'Stub Hub' },
    // { id: 4, name: 'Seat Geek' },
    // { id: 5, name: 'Tick Pick' },
    { id: 13, name: 'Go Tickets' }
];

const MappingOptions = [
    { id: -1, name: 'Any' },
    { id: 0, name: 'Unmapped' },
    { id: 1, name: 'Mapped' },
    { id: 2, name: 'Unmatched' }
];

const Countries = [
    { id: 'US', name: 'US' },
    { id: 'MX', name: 'MX' },
    { id: 'CA', name: 'CA' }
];

const Shipping = [
    { id: "Delayed", name: 'Delayed Until' },
    { id: "Delivered", name: 'Delivered' },
    { id: "No Label", name: 'No label' },
    { id: "Shipped", name: 'Shipped' },
    { id: "Alt Delivery", name: 'Alt Delivery' },
    { id: "E-Ticket : Not Uploaded", name: 'E-Ticket : Not Uploaded' },
    { id: "E-Ticket : Uploaded", name: 'E-Ticket : Uploaded' },
    { id: "E-Ticket : Downloaded", name: 'E-Ticket : Downloaded' },
]

const ProblemList = [
    { id: "#", name: 'Process/Ship by mistake' },
    { id: "Offered Alts", name: 'Offered Alts' },
    { id: "Will-Call", name: 'Will-Call' },
    { id: "Tickets On Sale", name: 'Tickets On Sale' },
    { id: "Waiting to Process", name: 'Waiting to Process' },
    { id: "Rejected", name: 'Rejected' },
    { id: "Awaiting VS Action", name: 'Awaiting VS Action' },
]

const UserStatusObj = {
    Draft: 0,
    Actived: 1,
    Disabled: -1,
    Deleted: -2
};

const UserStatus = Object.entries(UserStatusObj).map(([name, id]) => ({ id, name }));

const TeamOptions = [
    { id: 1, name: "VN" },
    { id: 2, name: "PH" }
];


// Order status
const STATUS_ENUM = {
    Unprocessed: 0,
    PendingShipment: 1,
    DelayedUnshippedOrders: 2,
    ProblemOrders: 3,
    Completed: 4,
    Unconfirmed: 5,
    Processing: 6,
    Shipping: 7,
    Missing: 8,
    Canceled: 9,
    AutoProcessing: 10,
    Rejected: 11,
    TNProcessing: 12,
    Decreasing: 13,
    Transfering: 14,
    NexusProblem: 15, // <=> Undelivered to mapping with in the enum OrderStatus of server
};
// End orderStatus

const STATUS_NAME = [
    'Unprocessed', 'Available Unshipped', 'Delayed Unshipped', 'Problem', 'Completed', 'Unconfirmed',
    'Processing', 'Shipping', 'Missing', 'Canceled', 'AutoProcessing', 'Rejected', 'TN Processing', 'Decreasing', 'Transfering', 'Undelivered'];

const DeliveryMethodColors = [
    { id: 0, name: 'PAH', label: 'pink', enum: 'ETicket', color: 'deeppink' },
    { id: 1, name: 'Hard', label: 'warning', enum: 'HardTicket', color: '#f0ad4e' },
    { id: 2, name: 'Fedex', label: 'warning', enum: 'Fedex', color: '#f0ad4e' },
    { id: 3, name: 'ME', label: 'success', enum: 'Mobile', color: '#5cb85c' },
    { id: 4, name: 'FS', label: 'primary', enum: 'FlashSeat', color: '#337ab7' },
    { id: 5, name: 'DT', label: 'primary', enum: 'DirectTransfer', color: '#337ab7' },
    { id: 6, name: 'WC', label: 'danger', enum: 'WillCall', color: '#d9534f' }
]

function getStatuses(allowed, excluded = []) {
    return (allowed || Object.keys(STATUS_ENUM).map(k => STATUS_ENUM[k])).filter(x => excluded.indexOf(x) == -1)
        .map(status => ({ id: status, name: STATUS_NAME[status] }));
}

function getTagsColor(tagName) {
    var warnColor = ['Awaiting TN Action', 'Preconfirm Rejected', 'Mutual', 'Missing', 'Tickets On Sale', 'Rejected', 'Awaiting VS Action', 'Mutual Cancellation', 'Offered Alts', 'Will Call',
        'Mistake', 'Denied Entry', 'Canceled Event', 'Skip Sync', 'Waiting to Process', 'Gecko', 'Non Transferable', 'POSTPONED', 'Needs to be refunded', 'Check CC refund', "BLUMENTHAL - SPECIAL ACCT REQ'D",
        'Special Accts', 'Shipping Mistake', 'Processing Mistake', 'venue refunded', 'Order Support', 'Wait To Ship', 'Delivery'
    ];
    var purpleColor = ['Auto-Rejected'];
    var grayColor = ['Divvy', 'AP Skip', 'AT Skip', 'AP Purchase Failed'];

    if (warnColor.find(item => item.toLowerCase() == tagName)) return '#f0ad4e';
    else if (purpleColor.find(item => item.toLowerCase() == tagName)) return '#800080';
    else if (grayColor.find(item => item.toLowerCase() == tagName)) return '#777';
    else return '#d9534f';
}

var deliveryCongig = {
    DELIVERY_MAP: {
        0: 'PAH',
        1: 'HT',
        2: 'FD',
        3: 'ME',
        4: 'FS',
        5: 'DT',
        6: 'WC',
    },
    EXCHANGEs:
        [
            {
                market_id: '1',
                folder_name: 'VS',
                mail_from: 'order_support@tickethq.com',
                mail_to: 'Vivid Seats Seller Services <brokers@vividseats.com>',
                delivery_skipped: [
                    {
                        to: 0,
                        from: [1, 6]// Skip HT, WC to PAH
                    },
                    {
                        parallel: true,
                        from_to: [3, 5]// ME <-> DT 
                    },
                    {
                        parallel: true,
                        from_to: [4, 3]// FS <-> ME
                    },
                    {
                        parallel: true,
                        from_to: [4, 5]// FS <-> DT
                    },
                    {
                        parallel: true,
                        from_to: [1, 2]// Skip FD <-> HT
                    }
                ]
            },
            {
                market_id: '2',
                folder_name: 'TN',
                mail_from: 'songpos3n@gmail.com',
                mail_to: 'MTT-Fulfillment <Fulfillment@mytickettracker.com>, fulfillment <Fulfillment@ticketnetwork.com>',
                delivery_from: [6], //WC to PAH/ME/DT/HT and opposite
                delivery_valid: [
                    {
                        from_to: [-1, 1] //* -> HT
                    },
                    {
                        from_to: [6, 0] //WC -> PAH
                    },
                    {
                        from_to: [6, 3] //WC -> ME
                    },
                    {
                        from_to: [6, 5] //WC -> DT
                    },
                    {
                        from_to: [-1, 6] //* -> WC
                    }
                ],
                delivery_skipped: [{
                    parallel: true,
                    from_to: [1, 2]// Skip FD <-> HT
                }]
            },
        ],
};

export {
    DeliveryMethodEnum,
    DeliveryMethodColors,
    ActionEnum,
    ExchangeMarket,
    MappingOptions,
    Countries,
    UserStatus,
    UserStatusObj,
    TeamOptions,
    Shipping,
    ProblemList,
    deliveryCongig,
    STATUS_ENUM,
    STATUS_NAME,
    getStatuses,
    getTagsColor
};